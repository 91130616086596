<template>
  <!-- Start Answer To Quation -->
  <pop-up-form
    title-popup="Answer this question"
    button-name="Answer"
    :showSubmitButton="
      dataQuestions.status === 'approved' || dataQuestions.status === 'answered'
    "
    :disabledStatus="buttonSubmitStatus"
    @submit-form="submitForm"
    :canselButtonName="false"
    :showPopup="showPopup"
    class="popup-answer"
    @canselPopup="canselPopup"
  >
    <template #body>
      <!-- This is a question -->
      <div class="quation-answer">
        <comment-component-ui
          v-bind:key="dataQuestions.question_id"
          :type="'user-question'"
          :name-comment="dataQuestions.name"
          :date="dataQuestions.created_at"
          :content-comment="dataQuestions.content"
          :quation-id="dataQuestions.question_id"
          :statusHiddenComment="false"
          :product-name="dataQuestions.product.product_name"
          :status="dataQuestions.status"
          @actionHide="hideAction"
          @deleteAction="deleteAction"
          @approveAction="approveAction"
          :loading-delete="dataQuestions.loadingDelete"
          :loading-hidden="dataQuestions.loadingHidden"
          :loading-published="dataQuestions.loadingPublished"
        >
          <template #right-section-header>
            <a
              class="product-link"
              :href="
                'https://as.lexmodo.com/products/' +
                dataQuestions.product.product_id
              "
              target="_top"
            >
              <span>{{ dataQuestions.product.product_name }} </span>
              <img src="/img/open.c7e46515.svg" alt="" data-v-22666fc4="" />
            </a>
          </template>
        </comment-component-ui>

        <!-- Start Answers -->
        <div class="answers">
          <comment-component-ui
            v-for="(answer, index) in dataQuestions.answers"
            v-bind:key="answer.id"
            :type="'user-answer'"
            :name-comment="answer.name"
            :date="answer.created_at"
            :content-comment="answer.content"
            :quation-id="answer.question_id"
            :indexData="index"
            :statusHiddenComment="false"
            :status="answer.status"
            @actionHide="hideAction"
            @deleteAction="deleteAction"
            @approveAction="approveAction"
            :loading-delete="answer.loadingDelete"
            :loading-hidden="answer.loadingHidden"
            :loading-published="answer.loadingPublished"
          >
            <template #right-section-header>
              <a class="product-link">
                <span>Aquila Alabastro </span>
                <img src="/img/open.c7e46515.svg" alt="" data-v-22666fc4="" />
              </a>
            </template>
          </comment-component-ui>

          <div
            class="answer-comment"
            style="padding: 10px 0px 10px 50px"
            v-if="
              dataQuestions.status === 'approved' ||
              dataQuestions.status === 'answered'
            "
          >
            <h5>Answer</h5>
            <textarea
              name=""
              id=""
              v-model="answerContent"
              @input="
                answerContent.length > 0
                  ? (buttonSubmitStatus = false)
                  : (buttonSubmitStatus = true)
              "
            ></textarea>
          </div>
        </div>
      </div>
    </template>
  </pop-up-form>
</template>

<script>
import PopUpForm from "@/components/Forms/PopUpForm.vue";
import CommentComponentUi from "@/components/UI/CommentComponentUi.vue";
import { useQuestionsEvents } from "@/service/QuestionStore/QuestionsEventsPopup";
import { storeMessages } from "@/service/storeMessages";

export default {
  name: "PopUpAnswer",
  data() {
    return {
      useQuestionsEvents: useQuestionsEvents(),
      answerContent: "",
      buttonSubmitStatus: true,
      SMessages: storeMessages(),
    };
  },
  components: {
    PopUpForm,
    CommentComponentUi,
  },
  props: {
    showPopup: {
      default: true,
      type: Boolean,
    },
    dataQuestions: {
      default: {},
    },
  },
  methods: {
    hideAction(obj) {
      // Start event to question
      if (obj.index === undefined) {
        this.$emit("updateLoadingQuestion", {
          ...this.dataQuestions,
          loadingHidden: true,
        });
        this.useQuestionsEvents.actionsOnQuestion(obj.id, "hide").then(() => {
          this.$emit("canselPopup");
        });
      }
    },

    submitForm() {
      let dataCreateAnswer = {
        question_id: this.dataQuestions.question_id,
        name: "Store Admin",
        email: "admin@com",
        content: this.answerContent,
      };

      this.useQuestionsEvents
        .addAnswerToQuestion(dataCreateAnswer)
        .then((data) => {
          if (data === "answer added successfully") {
            this.$emit("canselPopup");
            this.answerContent = "";
            this.buttonSubmitStatus = true;
            this.SMessages.messages.push({
              severity: "success",
              content: "answer added successfully",
            });
          }
        });
    },

    deleteAction(obj) {
      if (obj.index === undefined) {
        this.$emit("updateLoadingQuestion", {
          ...this.dataQuestions,
          loadingDelete: true,
        });
        this.useQuestionsEvents
          .actionsOnQuestion(obj.id, "deleted")
          .then(() => {
            this.$emit("canselPopup");
          });
      }
    },

    approveAction(obj) {
      if (obj.index === undefined) {
        this.$emit("updateLoadingQuestion", {
          ...this.dataQuestions,
          loadingPublished: true,
        });
        this.useQuestionsEvents
          .actionsOnQuestion(obj.id, "approve")
          .then(() => {
            this.$emit("canselPopup");
          });
      }
    },
    canselPopup() {
      this.$emit("canselPopup");
    },
  },
};
</script>

<style lang="scss">
.popup-answer {
  width: 800px;
}
.product-link {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #1575bf;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
}

.answer-comment {
  textarea {
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    margin-top: 5px;
    min-height: 135px;
    padding: 10px 11px;
    font-size: 14px;
    color: #202223;
    outline: none;
  }
}
</style>
